<script setup lang="ts">
import { FormKitIcon, useFormKitNodeById } from "@formkit/vue";
import { getNode, type FormKitNode } from "@formkit/core";
import { useFormStore } from "@/stores/form";
import type { SubmitData } from "~/types";

const props = defineProps({
  focusOnMount: {
    type: Boolean,
    default: false,
  },
  initialMessage: {
    type: String,
    default: "",
  },
  inputId: {
    type: String,
    default: "initialPrompt",
  },
  useDynamicPlaceholder: {
    type: Boolean,
    default: false,
  },
  promptTarget: {
    type: Boolean,
    default: false,
  },
  shadow: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["submit"]);

const form = useFormStore();
const userStore = useUserStore();
const marketing = useMarketingStore();
const system = useSystemStore();
const isLoading = ref(false);

const { selectedPrompt } = storeToRefs(marketing);
const { formModalOpen } = storeToRefs(system);

const placeholderPrompts = [
  "I need to be able to accept requests for my wedding photography business.",
  "My kid's school is having a bake sale and I need a form for people to sign up.",
  "A form that accepts scheduling requests and payment for haircuts. Should have various prices for different services.",
  "I need a form that allows users to submit their favorite recipes.",
  "I need a question-by-question survey that collects information about a user’s experience with a product.",
  "A form for entering new customer information into my company's CRM.",
  "I need to collect feedback from club members about our latest happy hour event.",
  "A registration form for a conference that includes a section for dietary restrictions and allows registering multiple attendees at once.",
];

const randomizedPlaceholders = placeholderPrompts.sort(
  () => Math.random() - 0.5
);

const currentIndex = ref(0);
const currentPlaceholder = ref("");
const deleteDelay = 8;
const pauseDelay = 3000;
const typingDelay = 18;
let typingInterval: ReturnType<typeof setInterval> | null = null;
let deleteInterval: ReturnType<typeof setInterval> | null = null;
const inputIsFocused = ref(false);
const inputContent = ref(props.initialMessage);

const startTyping = () => {
  if (!props.useDynamicPlaceholder) return;
  stopTyping();
  typeWord();
};

const stopTyping = () => {
  if (typingInterval) {
    clearInterval(typingInterval);
    typingInterval = null;
  }
  if (deleteInterval) {
    clearInterval(deleteInterval);
    deleteInterval = null;
  }
};

const typeWord = () => {
  const word = randomizedPlaceholders[currentIndex.value];
  let index = 0;

  typingInterval = setInterval(() => {
    if (index < word.length) {
      currentPlaceholder.value += word[index];
      index++;
    } else {
      stopTyping();
      deleteInterval = setTimeout(() => {
        deleteWord();
      }, pauseDelay);
    }
  }, typingDelay);
};

const deleteWord = () => {
  typingInterval = setInterval(() => {
    if (currentPlaceholder.value.length > 0) {
      currentPlaceholder.value = currentPlaceholder.value.slice(0, -1);
    } else {
      stopTyping();
      currentIndex.value =
        (currentIndex.value + 1) % randomizedPlaceholders.length;
      startTyping();
    }
  }, deleteDelay);
};

const handleFocus = () => {
  inputIsFocused.value = true;
  if (typingInterval) {
    clearInterval(typingInterval);
    typingInterval = null;
  }
  currentPlaceholder.value = "";
};

const handleBlur = () => {
  inputIsFocused.value = false;
  if (!inputContent.value) {
    startTyping();
  }
};

watch(inputContent, (newValue) => {
  if (!newValue && !inputIsFocused.value) {
    startTyping();
  }
});

onMounted(() => {
  // focus input on timeout
  if (props.focusOnMount) {
    setTimeout(() => {
      const input = document.getElementById(
        `${props.inputId}-input`
      ) as HTMLInputElement;
      input?.focus();
      // set cursor to end of input
      input?.setSelectionRange(
        inputContent.value.length,
        inputContent.value.length
      );
    }, 100);
  }

  startTyping();
});

onUnmounted(() => {
  stopTyping();
});

const submitForm = async (data: SubmitData, node: FormKitNode) => {
  isLoading.value = true;
  try {
    await form.createForm(data, (id) => {
      formModalOpen.value = false;
      useRouter().push(`/editor/${id}#form-page-1`);
    });
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    isLoading.value = false;
    emit("submit");
  }
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    getNode(`${props.inputId}-form`)?.submit();
  }
};

watch(selectedPrompt, (newValue) => {
  if (newValue && props.promptTarget) {
    const formNode = useFormKitNodeById(`${props.inputId}-form`);
    if (formNode.value && formNode.value.context) {
      formNode.value.input({
        prompt: newValue.value,
        file: newValue.attachment ? [{ name: newValue.attachment }] : "",
        layout: newValue.layout,
        flow: newValue.flow,
        owner: formNode.value?.value?.owner,
      });
    }
  }
});
</script>

<template>
  <div class="relative flex w-full flex-col">
    <div
      :class="`
        z-10
        my-auto
        flex
        w-full
        flex-col
        divide-zinc-600
        overflow-hidden
        rounded-xl
        bg-white
        border
        border-slate-400/70
        focus-within:ring-2
        ring-pink-300
        focus-within:border-pink-300

        sm:max-w-xl

        ${
          shadow
            ? 'shadow-lg shadow-pink-600/10 focus-within:shadow-xl focus-within:shadow-pink-500/20'
            : 'shadow-none'
        }
      `"
    >
      <FormKit
        :id="`${props.inputId}-form`"
        type="form"
        :actions="false"
        :incomplete-message="false"
        form-class="$reset relative z-10 h-full w-full min-w-0 p-3 pb-1 md:pl-4"
        @submit="submitForm"
      >
        <div
          class="relative flex w-full flex-1 items-center transition-all duration-300 flex-col gap-3"
        >
          <div class="relative flex flex-wrap items-end w-full min-w-0 flex-1">
            <FormKit
              :id="`${props.inputId}-input`"
              v-model="inputContent"
              type="textarea"
              name="prompt"
              validation-label="A prompt"
              auto-height
              :placeholder="
                inputIsFocused
                  ? 'Unleash your imagination...'
                  : useDynamicPlaceholder
                  ? currentPlaceholder
                  : 'Unleash your imagination...'
              "
              outer-class="!mb-0 !max-w-none order-1"
              inner-class="$reset !bg-transparent"
              :input-class="`
                $reset
                !h-[4.25em]
                overflow-clip
                w-full
                flex-[1_0_50%]
                resize-none
                border-0
                bg-transparent
                pr-2
                text-base
                md:text-lg
                !leading-tight
                shadow-none
                outline-none
                ring-0
                [scroll-padding-block:0.75rem]
                placeholder:text-slate-500/70
                disabled:bg-transparent
                disabled:opacity-80
                [&amp;_textarea]:px-0
                !text-slate-600
              `"
              messages-class="block -mb-1 -mt-6"
              message-class="!-mb-0 !mt-4 ml-0 inline-flex px-2 py-1 bg-blue-800 !text-blue-100 rounded-full"
              validation-visibility="submit"
              validation="require_one:file"
              @keydown="handleKeyDown"
              @focus="handleFocus"
              @blur="handleBlur"
            />
            <div class="w-full order-2 flex justify-end">
              <div class="mr-2">
                <FormKit
                  :id="`${props.inputId}-image`"
                  help="Reference File"
                  file-item-icon="file"
                  no-files-icon="file"
                  validation-label="reference file"
                  outer-class="$reset w-auto text-xs relative"
                  no-files-class="!text-slate-500 whitespace-nowrap"
                  accept=".jpg,.jpg,.gif,.png,.webp,.csv,.sql,.json,.jsonp,.txt,.md,.rtf,.xml,.html,.php,.js,.ts,.vue,.tsx,.yaml,.yml,.log,.ini,.tsv,.latex,.tex,.conf,.env,.sh,.ini"
                  inner-class="$reset border border-slate-400/60 rounded-md p-2 w-full hover:bg-slate-50 max-w-[120px]"
                  file-item-icon-class="[&>svg]:h-[1em]"
                  file-remove-class="ml-2"
                  file-name-class="block leading-none"
                  help-class="!text-slate-400 ml-1"
                  name="file"
                  type="file"
                />
              </div>
              <FormKit type="group" name="owner" v-if="!userStore.user?.id">
                <FormKit type="meta" name="anon_id" :value="userStore.anonId" />
              </FormKit>
              <div v-else class="min-w-44 w-auto mr-2">
                <TeamSelectionDropdown help="Choose a team" />
              </div>
              <FormKit
                type="submit"
                outer-class="$reset ml-auto"
                input-class="$reset block w-9 rounded-full aspect-square bg-slate-800 text-slate-200 flex items-center justify-center hover:bg-slate-600"
              >
                <Loader class="w-5 h-5 animate-spin" v-if="isLoading" />
                <FormKitIcon
                  class="block w-3 aspect-square"
                  v-else
                  icon="arrowUp"
                />
              </FormKit>
            </div>
          </div>
        </div>
      </FormKit>
    </div>
    <slot name="post" />
  </div>
</template>
